<template>
    <div>
        <div class="modal_header">
            <el-row >
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.update_access_rights') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button  @click="submit" :loading="loadingButton" :disabled="loadingButton ? true : false" type="success">{{ $t('message.save_and_close') }}</el-button>
                           <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'left'" label-width="200px" class="aticler_m">
            <el-col :span="22">
                <el-form-item :label="columns.name.title" prop="name">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="columns.slug.title" prop="slug">
                    <el-input disabled v-model="form.slug"></el-input>
                </el-form-item>
                <el-form-item :label="columns.parent_id.title">
                    <el-select disabled v-model="form.parent_id" :placeholder="$t('message.choose_status')" filterable clearable>
                        <el-option v-for="item in parent_permissions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-form>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';
    export default {
        mixins:[form],
        props: {
            reloadModel: {type: Boolean, default: true}
        },
        data(){
            return {}
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        created(){
            if (this.parent_permissions && this.parent_permissions.length === 0)
                this.loadParentPermissions()
        },
        computed: {
            ...mapGetters({
                permissions: 'permissions/list',
                parent_permissions: 'permissions/parent_permissions',
                rules: 'permissions/rules',
                model: 'permissions/model',
                columns: 'permissions/columns'
            })
        },
        methods:{
            ...mapActions({
                save: 'permissions/update',
                updateList: 'permissions/index',
                loadParentPermissions: 'permissions/parent_permissions'
            }),
            loadModel(){
                this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;                                
                                this.$alert(res);
                                this.parent().listChanged()
                                this.resetForm('form');
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('c-close',{drawer: 'drawerUpdate'});
            },
        }
    }

</script>
